<!-- 双色球蓝球基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>双 色 球 蓝 球 基 本 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <!-- <th rowspan="2" class="ranks_a1">星期</th> -->
                        <th colspan="16" class="ranks_b">蓝球基本走势图</th>
                        <th colspan="4" class="ranks_b">1234走势图</th>
                        <th colspan="4" class="ranks_b">0129走势图</th>
                        <th colspan="4" class="ranks_b">3456走势图</th>
                        <th colspan="16" class="ranks_b">蓝球振荡走势图</th>
                        <th class="ranks_d dc">01</th>
                        <th class="ranks_d dc">02</th>
                        <th class="ranks_d dc">03</th>
                        <th class="ranks_d dc">04</th>
                        <th class="ranks_d dc">05</th>
                        <th class="ranks_d dc">06</th>
                        <th class="ranks_d dc">07</th>
                        <th class="ranks_d dc">08</th>
                        <th class="ranks_d de">01</th>
                        <th class="ranks_d de">02</th>
                        <th class="ranks_d de">03</th>
                        <th class="ranks_d de">04</th>
                        <th class="ranks_d de">05</th>
                        <th class="ranks_d de">06</th>
                        <th class="ranks_d de">07</th>
                        <th class="ranks_d de">08</th>
                    </tr>
                    <tr>
                        <th class="ranks_d">01</th>
                        <th class="ranks_d">02</th>
                        <th class="ranks_d">03</th>
                        <th class="ranks_d">04</th>
                        <th class="ranks_d">05</th>
                        <th class="ranks_d">06</th>
                        <th class="ranks_d">07</th>
                        <th class="ranks_d">08</th>
                        <th class="ranks_d">09</th>
                        <th class="ranks_d">10</th>
                        <th class="ranks_d">11</th>
                        <th class="ranks_d">12</th>
                        <th class="ranks_d">13</th>
                        <th class="ranks_d">14</th>
                        <th class="ranks_d">15</th>
                        <th class="ranks_d">16</th>
                        <th class="ranks_c"> 1</th>
                        <th class="ranks_c"> 2</th>
                        <th class="ranks_c"> 3</th>
                        <th class="ranks_c"> 4</th>

                        <th class="ranks_c"> 0</th>
                        <th class="ranks_c"> 1</th>
                        <th class="ranks_c"> 2</th>
                        <th class="ranks_c"> 9</th>

                        <th class="ranks_c"> 3</th>
                        <th class="ranks_c"> 4</th>
                        <th class="ranks_c"> 5</th>
                        <th class="ranks_c"> 6</th>
                        <th class="ranks_d">0</th>
                        <th class="ranks_d">1</th>
                        <th class="ranks_d">2</th>
                        <th class="ranks_d">3</th>
                        <th class="ranks_d">4</th>
                        <th class="ranks_d">5</th>
                        <th class="ranks_d">6</th>
                        <th class="ranks_d">7</th>
                        <th class="ranks_d">8</th>
                        <th class="ranks_d">9</th>
                        <th class="ranks_d">10</th>
                        <th class="ranks_d">11</th>
                        <th class="ranks_d">12</th>
                        <th class="ranks_d">13</th>
                        <th class="ranks_d">14</th>
                        <th class="ranks_d">15</th>
                        <th class="ranks_d dc">16</th>
                        <th class="ranks_d dc">15</th>
                        <th class="ranks_d dc">14</th>
                        <th class="ranks_d dc">13</th>
                        <th class="ranks_d dc">12</th>
                        <th class="ranks_d dc">11</th>
                        <th class="ranks_d dc">10</th>
                        <th class="ranks_d dc">09</th>
                        <th class="ranks_d de">09</th>
                        <th class="ranks_d de">11</th>
                        <th class="ranks_d de">13</th>
                        <th class="ranks_d de">15</th>
                        <th class="ranks_d de">10</th>
                        <th class="ranks_d de">12</th>
                        <th class="ranks_d de">14</th>
                        <th class="ranks_d de">16</th>
                    </tr>
                </thead>
            
                <tbody>
                    <tr v-for="(item2 ,x) in ssq" :key='x'>
                        <td class="ranks_a">{{item2.expect}}</td>
                        <!-- <td>{{}}</td> -->
                        <!-- 蓝球走势 -->
                        <td class="ssq-lan ranks_d" v-for="(item3 ,i) in lan" :key='i'>
                            <div :class="item3 == item2.lan? 'lan' : ''">
                                {{item3 == item2.lan ?item3:''}}
                            </div>
                        </td>
                        <!-- 四区1234走势图 -->
                        <td class="ssq-red ranks_d" v-for="(item ,i) in s1" :key='i'>
                            <div :class="item == (
                                (4>=item2.lan)%10 ? 1 :''||(8>=item2.lan)%10 && (item2.lan>4)%10 ? 2 :'' || (12>=item2.lan)%10 && (item2.lan>8)%10 ? 3 :'' || (item2.lan>12)%10 ? 4 :'') ?
                                'red':'' ">
                                {{item == ( (4>=item2.lan)%10 ? 1 :''||(8>=item2.lan)%10 && (item2.lan>4)%10
                                ? 2 :'' ||
                                (12>=item2.lan)%10 && (item2.lan>8)%10 ? 3 :'' || (item2.lan>12)%10 ? 4 :'')
                                ?
                                item:'' }}
                            </div>
                        </td>
                        <!-- 四区0129走势图 -->
                        <td class="ssq-lan ranks_d" v-for="(item ,i) in s2" :key='i'>
                            <div :class=" item == ((['10','12','14','16'].indexOf(item2.lan)==-1 ? '' :
                            0)||(['01','03','05','07'].indexOf(item2.lan)==-1 ? '' :
                            1)||(['02','04','06','08'].indexOf(item2.lan)==-1 ? '' : 2)
                            ||(['09','11','13','15'].indexOf(item2.lan)==-1 ? '' : 9)) ? 'lan' : '' ">
                                {{ item == ((['10','12','14','16'].indexOf(item2.lan)==-1 ? '' :
                                0)||(['01','03','05','07'].indexOf(item2.lan)==-1 ? '' :
                                1)||(['02','04','06','08'].indexOf(item2.lan)==-1 ? '' : 2)
                                ||(['09','11','13','15'].indexOf(item2.lan)==-1 ? '' : 9)) ? item : '' }}
                            </div>
                        </td>
                        <!-- 四区3456走势图 -->
                        <td class="ssq-red ranks_d" v-for="(item ,i) in s3" :key='i'>
                            <div :class=" item == ((['01','03','10','12'].indexOf(item2.lan)==-1 ? '' :
                            3)||(['02','04','09','11'].indexOf(item2.lan)==-1 ? '' :
                            4)||(['05','07','14','16'].indexOf(item2.lan)==-1 ? '' : 5)
                            ||(['06','08','13','15'].indexOf(item2.lan)==-1 ? '' : 6)) ? 'red' : ''">
                                {{ item == ((['01','03','10','12'].indexOf(item2.lan)==-1 ? '' :
                                3)||(['02','04','09','11'].indexOf(item2.lan)==-1 ? '' :
                                4)||(['05','07','14','16'].indexOf(item2.lan)==-1 ? '' : 5)
                                ||(['06','08','13','15'].indexOf(item2.lan)==-1 ? '' : 6)) ? item : '' }}
                            </div>
                        </td>
                        <!-- 蓝球振荡走势图 -->
                        <td class="ssq-lan ranks_d" v-for="(item3 ,i) in zf" :key='i'>
                            <div :class="item3 == zf2[x]? 'lan' : ''">
                                {{item3 == zf2[x]? item3 : ''}}
                            </div>
                        </td>
                        <!-- 蓝球两数相加17走势图 -->
                        <td class="ssq-red ranks_d" v-for="(item3 ,i) in lie" :key='i'>
                            <div :class="(item3==1 ?(1 == item2.lan ? 'red':'')||(16 == item2.lan ? 'red':''):'')
                            ||(item3==2 ?(2 == item2.lan ? 'red':'')||(15 == item2.lan ? 'red':''):'')
                            ||(item3==3 ?(3 == item2.lan ? 'red':'')||(14 == item2.lan ? 'red':''):'')
                            ||(item3==4 ?(4 == item2.lan ? 'red':'')||(13 == item2.lan ? 'red':''):'')
                            ||(item3==5 ?(5 == item2.lan ? 'red':'')||(12 == item2.lan ? 'red':''):'')
                            ||(item3==6 ?(6 == item2.lan ? 'red':'')||(11 == item2.lan ? 'red':''):'')
                            ||(item3==7 ?(7 == item2.lan ? 'red':'')||(10 == item2.lan ? 'red':''):'')
                            ||(item3==8 ?(8 == item2.lan ? 'red':'')||(9 == item2.lan ? 'red':''):'')">
                                {{
                                (item3==1 ?(1 == item2.lan ? 1:'')||(16 == item2.lan ? 16:''):'')
                                ||(item3==2 ?(2 == item2.lan ? 2:'')||(15 == item2.lan ? 15:''):'')
                                ||(item3==3 ?(3 == item2.lan ? 3:'')||(14 == item2.lan ? 14:''):'')
                                ||(item3==4 ?(4 == item2.lan ? 4:'')||(13 == item2.lan ? 13:''):'')
                                ||(item3==5 ?(5 == item2.lan ? 5:'')||(12 == item2.lan ? 12:''):'')
                                ||(item3==6 ?(6 == item2.lan ? 6:'')||(11 == item2.lan ? 11:''):'')
                                ||(item3==7 ?(7 == item2.lan ? 7:'')||(10 == item2.lan ? 10:''):'')
                                ||(item3==8 ?(8 == item2.lan ? 8:'')||(9 == item2.lan ? 9:''):'')
                                }}
                            </div>
                        </td>

                        <!-- 蓝球两数走势图 -->
                        <td class="ssq-lan ranks_d" v-for="(item3 ,i) in lie" :key='i'>
                            <div :class="(item3==1 ?(1 == item2.lan ? 'lan':'')||(9 == item2.lan ? 'lan':''):'')
                            ||(item3==2 ?(2 == item2.lan ? 'lan':'')||(11 == item2.lan ? 'lan':''):'')
                            ||(item3==3 ?(3 == item2.lan ? 'lan':'')||(13 == item2.lan ? 'lan':''):'')
                            ||(item3==4 ?(4 == item2.lan ? 'lan':'')||(15 == item2.lan ? 'lan':''):'')
                            ||(item3==5 ?(5 == item2.lan ? 'lan':'')||(10 == item2.lan ? 'lan':''):'')
                            ||(item3==6 ?(6 == item2.lan ? 'lan':'')||(12 == item2.lan ? 'lan':''):'')
                            ||(item3==7 ?(7 == item2.lan ? 'lan':'')||(14 == item2.lan ? 'lan':''):'')
                            ||(item3==8 ?(8 == item2.lan ? 'lan':'')||(16 == item2.lan ? 'lan':''):'')">
                                {{
                                (item3==1 ?(1 == item2.lan ? 1:'')||(9 == item2.lan ? 9:''):'')
                                ||(item3==2 ?(2 == item2.lan ? 2:'')||(11 == item2.lan ? 11:''):'')
                                ||(item3==3 ?(3 == item2.lan ? 3:'')||(13 == item2.lan ? 13:''):'')
                                ||(item3==4 ?(4 == item2.lan ? 4:'')||(15 == item2.lan ? 15:''):'')
                                ||(item3==5 ?(5 == item2.lan ? 5:'')||(10 == item2.lan ? 10:''):'')
                                ||(item3==6 ?(6 == item2.lan ? 6:'')||(12 == item2.lan ? 12:''):'')
                                ||(item3==7 ?(7 == item2.lan ? 7:'')||(14 == item2.lan ? 14:''):'')
                                ||(item3==8 ?(8 == item2.lan ? 8:'')||(16 == item2.lan ? 16:''):'')
                                }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
     import mitt from '@/js/mitt.js'
    //  import '@/js/ssqtable-thead-fixed.js' //js文件引入，注意路径
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'red-basic',
        components: {

        },
        data() { // 数据源
            return {
                red: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
                lan: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                zf: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,],
                zf2: [0],
                lie: [1, 2, 3, 4, 5, 6, 7, 8],
                s1: [1, 2, 3, 4],
                s2: [0, 1, 2, 9],
                s3: [3, 4, 5, 6],
            }
        },
        mounted() {
            this.find()
            this.mzf()
        },
        computed: {
            ssq() {
                return this.$store.state.ssq
            }
        },
        methods: { // 函数体部分，js的主要逻辑控制 
            find() {
                document.documentElement.scrollTop = 0;
                if(this.ssq==''){
                    mitt.emit('mymsg');   
                }
            },  
            mzf() {//蓝球振幅
                var arr = new Array()
                for (var i = 0; i < this.ssq.length - 1; i++) {
                    arr = this.ssq[i].lan - this.ssq[i + 1].lan
                    this.zf2.push(Math.abs(arr))
                }
            },
        },
        props: {
        },
        watch: {
            ssq() {
                this.mzf()
            }
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    tr .ranks_a {
        width: 60px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {

        width: 22px;
        color: rgb(248, 7, 7);
    }

    .dc {
        color: rgb(0, 4, 255);
        background-color: rgb(214, 206, 216);
    }

    .de {
        color: rgb(253, 9, 9);
        background-color: rgb(214, 206, 216);
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .ssq-red {
        height: 23px;
        background-color: rgb(224, 191, 191);
    }

    .red {
        background-color: red;
        border-radius: 15px;
        color: #fff;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    tbody .lan {
        background-color: blue;
        color: #fff;
        border-radius: 15px;
    }

    .ssq-lan {
        background-color: rgb(156, 205, 211);
    }
    .title-guding {
        position: fixed;
        top: -1px;
        background-color: #fff;
       
    }
    .no {
        display: none
    }
</style>